 //toggle
$(function(){
  $('.child-box').hide();
  $('.s-nav-wrap').hide();
});
$(document).on('click', '.parent', function(e){ //DOM要素を動かす
  e.preventDefault();
  $(this).next('.child-box').slideToggle();
  $(this).toggleClass('open');
  //自分以外で開いているやつを閉じる
  $(this).parent().parent().find(".parent").not(this).next('.child-box').hide();
  $(this).parent().parent().find(".parent").not(this).removeClass('open');
});

 //nav-list
$(function(){
  $('.nav-menu').clone().removeClass().addClass('s-nav-menu').prependTo('.s-nav-wrap');
});
$('#burger').on('click', function(){
  $(this).toggleClass("open-menu");
  $('.s-nav-wrap').fadeToggle(100);
  $('.visual-wrapper, .sub-visual-wrapper, main, .fixed-button-list, #pagetopSm, footer').toggleClass('locked');
  $('.child-box').hide();
});

//マウスホイールを戻すとパンくずが出る
const target = $('#scroll-show');
//開始位置
var startPos = 0;
//スクロール初期値
var winScrollTop = 0;
$(window).on('scroll',function(){
    //スクロールしたら、現在のスクロール位置を入れる
    winScrollTop = $(this).scrollTop();
    //現在位置が開始位置と等しいか大きいとき（通常スクロール）
    if (winScrollTop >= startPos) {
      //隠す
      target.removeClass('show');
    } 
    //小さい時（逆スクロール）
    else {
      //スクロール位置が100pxより上のとき
      if(winScrollTop < 100){
        //隠す
        target.removeClass('show');
      }
      //スクロール位置が100pxより下のとき
      else{
        //出す
        target.addClass('show');
      }
    }
    //リセット
    startPos = winScrollTop;
});

//メニューの今いる階層をを開いたままにする
$(function(){
  //ファイルまでのパスを取得
  var pathname = $(location).attr('pathname');
  //パラメータも取得
  var param = $(location).attr('search');
  //パス＋パラメータを格納
  var path = pathname + param;
  //実行
  if(path !== "/"){
    $('.nav-list').find('a[href="'+path+'"]').addClass('current').parents('.child-box').show().prev('.parent').addClass('open');
  }
});





